/* eslint-disable arrow-body-style */
import { Grid, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import DocumentDisplay from 'components/document/document-display';
import { SmallSpacer, LargeSpacer } from 'components/spacer/spacer';
import { editMedicalInsurance, fetchMedicalInsurances } from 'actions/action-financials';
import moment from 'moment';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getDocumentsForDisplay } from 'services/utils/document-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { VerificationPanel } from 'components/form/verification/verification-panel';
import { getUserById } from 'services/utils/users-service';
import classNames from 'classnames';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import SubHeader from '../../../../components/form/header/subheader';
import { styles } from '../financials-styles';
import DetailField from '../../../../components/form/field/field';
import EditMedicalInsurance from '../medical-insurances/edit-medical-insurance';
import { MEDICAL_INSURANCE, EDIT_MEDICAL_INSURANCE_FORM, COMMERCIAL } from '../../../../constants';
import { noteTagTypes } from '../../../../constants/lists';

const tagType = noteTagTypes.find(tag => tag.label === MEDICAL_INSURANCE);

class MedicalBenefitTableDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleVerify = this.handleVerify.bind(this);
  }

  handleVerify(medicalInsurance, field = 'is_verified', value = 1) {
    const {
      editMedicalInsurance, //eslint-disable-line
      fetchMedicalInsurances, //eslint-disable-line
      patientId,
    } = this.props;
    const payload = {
      ...medicalInsurance,
      no_insurance: medicalInsurance.is_no_insurance,
      policy_holder_dob: medicalInsurance.policy_holder_dob
        ? convertToArborDate(medicalInsurance.policy_holder_dob).getUtcDate()
        : null,
      start_date: medicalInsurance.start_date
        ? convertToArborDate(medicalInsurance.start_date).getUtcDate()
        : null,
      end_date: medicalInsurance.end_date
        ? convertToArborDate(medicalInsurance.end_date).getUtcDate()
        : null,
      [field]: value,
      verified_dt: convertToArborDate(moment()).getUtcDatetime(),
    };

    if (field === 'valid') {
      payload.end_date = value ? null : convertToArborDate(moment()).getUtcDate();
    }

    editMedicalInsurance(payload).then(() => {
      fetchMedicalInsurances(patientId);
    });
  }

  displayDetail(medicalInsurance) {
    const { classes, users, planSponsors, stretch, uploadedDocuments } = this.props;
    const isNoInsurance = Boolean(medicalInsurance.is_no_insurance);
    const verifiedUser = getUserById(medicalInsurance.verified_by, users);

    const getDocuments = (resourceId, tagTypeId) =>
      getDocumentsForDisplay(uploadedDocuments.documents, resourceId, tagTypeId);

    const containerDivClassname = classNames(classes.paddedTop, {
      [classes.containerDivStretched]: stretch,
    });

    if (isNoInsurance) {
      return (
        <div className={containerDivClassname}>
          <Grid container>
            <Grid item xs={12}>
              <VerificationPanel
                type="pbm"
                isVerified={medicalInsurance.is_verified}
                handleVerify={() => this.handleVerify(medicalInsurance)}
                verifiedBy={verifiedUser}
                verifiedDate={medicalInsurance.verified_dt}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
    const planSponsorName = planSponsors.find(o => o.id === medicalInsurance.plan_sponsor)?.type;
    return (
      <div className={containerDivClassname}>
        <Grid container alignItems="flex-end">
          <Grid item xs={3}>
            <DetailField fieldName="Insurance Plan Name" field={medicalInsurance.plan_name} />
          </Grid>
          <Grid item xs={3}>
            <TalkdeskPhoneNumber
              number={medicalInsurance.plan_phone_number}
              headerText="Insurance Plan Phone Number"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="Group ID" field={medicalInsurance.group_number} variant="raw" />
          </Grid>
          <Grid item xs={3}>
            <DocumentDisplay
              containerClass={classes.innerDocumentContainer}
              taskIdResourceIds={[
                {
                  tagTypeId: tagType.value,
                  resourceId: medicalInsurance.id,
                },
              ]}
              tagTypeId={tagType.value}
              resourceId={medicalInsurance.id}
              drugName={MEDICAL_INSURANCE}
              tagTypeLabel={medicalInsurance.insurance_type}
              documents={getDocuments(medicalInsurance.id, tagType.value)}
            />
          </Grid>
        </Grid>
        <SmallSpacer />
        <Grid container alignItems="flex-end">
          <Grid item xs={3}>
            <DetailField fieldName="Member ID" field={medicalInsurance.member_id} variant="raw" />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={3}>
            <Button
              onClick={() =>
                this.handleVerify(medicalInsurance, 'valid', Number(!medicalInsurance.valid))
              }
              color="secondary"
              variant="contained"
            >
              {medicalInsurance.valid ? 'Mark Inactive' : 'Reactivate'}
            </Button>
          </Grid>
        </Grid>
        <SmallSpacer />
        <SmallSpacer />
        <Grid container alignItems="flex-end">
          <Grid item xs={12}>
            <SubHeader name="Policy Holder" />
            <SmallSpacer />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Policy Holder Name"
              field={`${
                medicalInsurance.policy_holder_last_name
                  ? medicalInsurance.policy_holder_last_name
                  : ''
              }, ${
                medicalInsurance.policy_holder_first_name
                  ? medicalInsurance.policy_holder_first_name
                  : ''
              }`}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Relationship to Patient"
              field={medicalInsurance.relationship_name}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Policy Holder Date of Birth"
              field={
                medicalInsurance.policy_holder_dob &&
                convertToArborDate(medicalInsurance.policy_holder_dob, true).getUtcDate(true)
              }
            />
          </Grid>
        </Grid>
        <SmallSpacer />
        <Grid container alignItems="flex-end">
          <Grid item xs={2}>
            <DetailField fieldName="Plan Sponsor" field={planSponsorName} />
          </Grid>
          {planSponsorName === COMMERCIAL && (
            <Grid item xs={3}>
              <DetailField fieldName="Employer" field={medicalInsurance.policy_holder_sponsor} />
            </Grid>
          )}
        </Grid>
        <LargeSpacer />
        <Grid container>
          <Grid item xs={12}>
            <VerificationPanel
              type="medical"
              isVerified={medicalInsurance.is_verified}
              handleVerify={() => this.handleVerify(medicalInsurance)}
              verifiedBy={verifiedUser}
              verifiedDate={medicalInsurance.verified_dt}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  renderPanel(medicalInsurance) {
    const { editItemMap, handleEditClose } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          {medicalInsurance &&
            (!editItemMap[`medicalBenefit${medicalInsurance.id}`] ? (
              this.displayDetail(medicalInsurance)
            ) : (
              <EditMedicalInsurance
                form={`${EDIT_MEDICAL_INSURANCE_FORM}_${medicalInsurance.id}`}
                medicalInsurance={medicalInsurance}
                cancel={handleEditClose}
              />
            ))}
        </Grid>
      </Grid>
    );
  }

  render() {
    return this.renderPanel(this.props.medicalInsurance);
  }
}

function mapStateToProps(state) {
  const { lookups, uploadedDocuments } = state;
  return {
    users: lookups.users,
    uploadedDocuments,
    planSponsors: lookups.planSponsors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchMedicalInsurances,
      editMedicalInsurance,
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(MedicalBenefitTableDetail);
