import {
  FETCH_SHIPPING_HOLIDAYS_REQUEST,
  ADD_SHIPPING_HOLIDAYS_REQUEST,
  FETCH_SHIPPING_HOLIDAYS_SUCCESS,
} from 'constants/index';
import {
  IHolidayFormFields,
  IRequestAddHolidayDate,
} from 'containers/application-manager/holidays/interface';
import HTTP from 'services/http';

export const fetchShippingHolidaysRequest = (customerId: number) => ({
  type: FETCH_SHIPPING_HOLIDAYS_REQUEST,
  payload: HTTP.get(`/shipping/customer/${customerId}/holidays`, {}),
});

export const fetchShippingHolidaysSuccess = (payload: any) => ({
  type: FETCH_SHIPPING_HOLIDAYS_SUCCESS,
  payload,
});

export const addShippingHoliday = (
  { holidayDate, holidayTypes }: IHolidayFormFields,
  customerId: number,
) => {
  const data: IRequestAddHolidayDate = {
    date: holidayDate,
    holidayTypes,
    customerId,
  };

  const request = HTTP.post('/shipping/customer/holidays', data);
  return {
    type: ADD_SHIPPING_HOLIDAYS_REQUEST,
    payload: request,
    customerId,
  };
};
