import React, { FC } from 'react';
import cx from 'classnames';
import { Grid, GridOwnProps } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';

// Icons
import { APIcon, SMSIcon } from 'components/icons/icons';
import IconPhoneOptIn from 'components/icons/IconPhoneOptIn';
import { RecordVoiceOverRounded } from '@mui/icons-material';
import { MedSyncStatusIcon } from 'containers/patient/med-sync/components/medSyncIcon';
import { IPhone, PhoneUsage } from 'interfaces/redux/IPhone';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { styles } from './display-only-bar.style';
import DisplayOnlyItem from './display-only-item';

interface DisplayOnlyProps extends WithStyles<typeof styles>, Omit<GridOwnProps, 'classes'> {
  adherencePackaging?: boolean;
  needsInterpreter?: boolean;
  medSync?: boolean;
  enrolledInSMS?: boolean;
  phones: IPhone[];
  patientAutoFCStatus: number;
  anchorTherapy: any;
}

const DisplayOnlyBar: React.FC<DisplayOnlyProps> = ({
  classes,
  adherencePackaging,
  needsInterpreter,
  medSync,
  enrolledInSMS,
  phones,
  patientAutoFCStatus,
  anchorTherapy,
  ...props
}) => {
  const { sourceNumber } = useTypedSelector(state => state.lookups);

  return (
    <Grid {...props}>
      {adherencePackaging && (
        <DisplayOnlyItem
          title="Adherence Packaging"
          className={classes.adherencePackaging}
          Icon={APIcon}
        />
      )}
      {needsInterpreter && (
        <DisplayOnlyItem
          title="Needs Interpreter"
          className={classes.needsInterpreter}
          Icon={RecordVoiceOverRounded}
        />
      )}
      {medSync && (
        <DisplayOnlyItem
          title="Med Sync"
          className={classes.medSync}
          Icon={MedSyncStatusIcon as FC}
        />
      )}
      {enrolledInSMS && (
        <>
          <DisplayOnlyItem
            title="Enrolled in SMS"
            className={classes.enrolledInSMS}
            Icon={SMSIcon}
          />
          <IconPhoneOptIn
            phones={phones}
            sourceNumber={sourceNumber}
            patientAutoFCStatus={patientAutoFCStatus}
            anchorTherapy={anchorTherapy}
          />
        </>
      )}
    </Grid>
  );
};

export default withStyles(styles)(DisplayOnlyBar);
