import { takeLatest, put, call } from 'redux-saga/effects';
import { isAxiosError } from 'axios';
import { notifyError, notifySuccess } from '../actions/action-notifications';
import {
  fetchShippingHolidaysRequest,
  fetchShippingHolidaysSuccess,
} from '../actions/action-shipping';
import { FETCH_SHIPPING_HOLIDAYS_REQUEST, ADD_SHIPPING_HOLIDAYS_REQUEST } from '../constants';

function* workerFetchShippingHolidays({ payload }) {
  if (isAxiosError(payload)) {
    yield put(notifyError(payload.response?.data?.message));
  }

  yield put(fetchShippingHolidaysSuccess(payload));
}

function* workerAddShippingHolidays({ payload, customerId }) {
  const response = yield call(() => payload);
  if (isAxiosError(response)) {
    yield put(notifyError(response.data?.message));
  }
  yield put(notifySuccess(response.data?.message));
  yield put(fetchShippingHolidaysRequest(customerId));
}

export function* watcherShippingsSaga() {
  yield takeLatest([FETCH_SHIPPING_HOLIDAYS_REQUEST], workerFetchShippingHolidays);
  yield takeLatest([ADD_SHIPPING_HOLIDAYS_REQUEST], workerAddShippingHolidays);
}
